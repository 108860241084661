import React from 'react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import {MDXProvider} from '@mdx-js/react'
import mdxComponents from './mdx/index'

import {RunTSX} from './mdx/RunTSX'
import {SourceCodeLink} from './mdx/SourceCodeLink'
import {OrgChart} from './mdx/orgchart'
import {WithFigcaption} from './mdx/WithFigcaption'
import {NoShare} from './mdx/NoShare'
import {RunFlutter} from './mdx/FlutterRunner'
import SlideEmbed from './slide/slide-embed'
import Device from './mdx/devices/devices'

const shortcodes = {
  WithFigcaption,
  NoShare,
  RunTSX,
  OrgChart,
  SlideEmbed,
  RunFlutter,
  SourceCodeLink,
  Device,
  ...mdxComponents,
}

export default function ExtendedMdxRender({children}) {
  return (
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}
