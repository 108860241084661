import React from 'react'
import { css } from '@emotion/core'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'

const icons = {
  coding: (
    <svg
      viewBox="0 0 1399 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
    >
      <path
        d="M393.5744 583.9872c-14.08 0-25.4976 13.704533-25.4976 30.600533 0 16.896 11.434667 30.600533 25.4976 30.600534 14.097067 0 25.514667-13.704533 25.514667-30.600534 0-16.896-11.434667-30.600533-25.514667-30.600533m605.7472 0c-14.08 0-25.514667 13.704533-25.514667 30.600533 0 16.896 11.434667 30.600533 25.514667 30.600534 14.08 0 25.4976-13.704533 25.4976-30.600534 0-16.896-11.4176-30.600533-25.4976-30.600533"
        fill="#202D40"
      ></path>
      <path
        d="M1297.800533 611.106133c-22.9888 8.072533-45.789867 0.8192-50.909866-16.213333-5.12-17.032533 9.386667-37.376 32.375466-45.4656 22.9888-8.072533 45.789867-0.8192 50.909867 16.213333 5.12 17.032533-9.386667 37.376-32.375467 45.4656M708.266667 955.733333C467.797333 955.374933 273.066667 844.458667 273.066667 685.7216a200.4992 200.4992 0 0 1 2.167466-28.791467l0.119467-0.887466c4.846933-31.197867 17.237333-78.813867 35.959467-106.257067C356.283733 486.126933 436.48 443.733333 527.991467 443.733333c70.434133 0 134.144 25.173333 180.2752 65.774934C754.414933 468.906667 818.107733 443.733333 888.541867 443.733333c91.511467 0 171.707733 42.3936 216.6784 106.052267 18.722133 27.4432 31.112533 75.0592 35.959466 106.257067l0.119467 0.887466a193.467733 193.467733 0 0 1 1.877333 18.875734c0.170667 3.293867 0.290133 6.587733 0.290134 9.898666 0 158.7712-194.730667 269.653333-435.2 270.0288M152.576 594.8928c-5.12 17.032533-27.921067 24.285867-50.909867 16.213333-23.005867-8.0896-37.495467-28.433067-32.375466-45.4656 5.12-17.032533 27.921067-24.285867 50.909866-16.213333 23.005867 8.0896 37.495467 28.433067 32.375467 45.4656m1142.869333-168.994133c-37.410133 0-70.1952 23.432533-88.5248 58.606933-62.3616-133.632-192.085333-257.4336-352.426666-308.411733C768 141.9776 707.242667 76.509867 699.733333 0c-7.509333 76.509867-68.266667 141.994667-154.7776 176.093867-160.324267 50.978133-290.065067 174.779733-352.426666 308.411733-18.3296-35.157333-51.0976-58.606933-88.507734-58.606933C46.574933 425.898667 0 481.160533 0 549.3248s46.574933 123.4432 104.021333 123.4432a90.845867 90.845867 0 0 0 48.384-14.165333C157.354667 879.9232 400.384 1023.607467 699.733333 1024c299.349333-0.375467 542.378667-144.059733 547.328-365.397333 14.455467 9.0112 30.890667 14.165333 48.384 14.165333 57.4464 0 104.021333-55.278933 104.021334-123.4432 0-68.164267-46.574933-123.426133-104.021334-123.426133"
        fill="#202D40"
      ></path>
    </svg>
  ),
  github: (
    <svg
      viewBox="0 0 1049 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
    >
      <path
        d="M524.979332 0C234.676191 0 0 234.676191 0 524.979332c0 232.068678 150.366597 428.501342 358.967656 498.035028 26.075132 5.215026 35.636014-11.299224 35.636014-25.205961 0-12.168395-0.869171-53.888607-0.869171-97.347161-146.020741 31.290159-176.441729-62.580318-176.441729-62.580318-23.467619-60.841976-58.234462-76.487055-58.234463-76.487055-47.804409-32.15933 3.476684-32.15933 3.476685-32.15933 53.019436 3.476684 80.83291 53.888607 80.83291 53.888607 46.935238 79.963739 122.553122 57.365291 152.97411 43.458554 4.345855-33.897672 18.252593-57.365291 33.028501-70.402857-116.468925-12.168395-239.022047-57.365291-239.022047-259.012982 0-57.365291 20.860106-104.300529 53.888607-140.805715-5.215026-13.037566-23.467619-66.926173 5.215027-139.067372 0 0 44.327725-13.906737 144.282399 53.888607 41.720212-11.299224 86.917108-17.383422 131.244833-17.383422s89.524621 6.084198 131.244833 17.383422C756.178839 203.386032 800.506564 217.29277 800.506564 217.29277c28.682646 72.1412 10.430053 126.029806 5.215026 139.067372 33.897672 36.505185 53.888607 83.440424 53.888607 140.805715 0 201.64769-122.553122 245.975415-239.891218 259.012982 19.121764 16.514251 35.636014 47.804409 35.636015 97.347161 0 70.402857-0.869171 126.898978-0.869172 144.282399 0 13.906737 9.560882 30.420988 35.636015 25.205961 208.601059-69.533686 358.967656-265.96635 358.967655-498.035028C1049.958663 234.676191 814.413301 0 524.979332 0z"
        fill="#191717"
      ></path>
      <path
        d="M199.040177 753.571326c-0.869171 2.607513-5.215026 3.476684-8.691711 1.738342s-6.084198-5.215026-4.345855-7.82254c0.869171-2.607513 5.215026-3.476684 8.691711-1.738342s5.215026 5.215026 4.345855 7.82254z m-6.953369-4.345856M219.900283 777.038945c-2.607513 2.607513-7.82254 0.869171-10.430053-2.607514-3.476684-3.476684-4.345855-8.691711-1.738342-11.299224 2.607513-2.607513 6.953369-0.869171 10.430053 2.607514 3.476684 4.345855 4.345855 9.560882 1.738342 11.299224z m-5.215026-5.215027M240.760389 807.459932c-3.476684 2.607513-8.691711 0-11.299224-4.345855-3.476684-4.345855-3.476684-10.430053 0-12.168395 3.476684-2.607513 8.691711 0 11.299224 4.345855 3.476684 4.345855 3.476684 9.560882 0 12.168395z m0 0M269.443034 837.011749c-2.607513 3.476684-8.691711 2.607513-13.906737-1.738342-4.345855-4.345855-6.084198-10.430053-2.607513-13.037566 2.607513-3.476684 8.691711-2.607513 13.906737 1.738342 4.345855 3.476684 5.215026 9.560882 2.607513 13.037566z m0 0M308.555733 853.526c-0.869171 4.345855-6.953369 6.084198-13.037566 4.345855-6.084198-1.738342-9.560882-6.953369-8.691711-10.430053 0.869171-4.345855 6.953369-6.084198 13.037566-4.345855 6.084198 1.738342 9.560882 6.084198 8.691711 10.430053z m0 0M351.145116 857.002684c0 4.345855-5.215026 7.82254-11.299224 7.82254-6.084198 0-11.299224-3.476684-11.299224-7.82254s5.215026-7.82254 11.299224-7.82254c6.084198 0 11.299224 3.476684 11.299224 7.82254z m0 0M391.126986 850.049315c0.869171 4.345855-3.476684 8.691711-9.560882 9.560882-6.084198 0.869171-11.299224-1.738342-12.168395-6.084197-0.869171-4.345855 3.476684-8.691711 9.560881-9.560882 6.084198-0.869171 11.299224 1.738342 12.168396 6.084197z m0 0"
        fill="#191717"
      ></path>
    </svg>
  ),
  gitlab: (
    <svg
      viewBox="0 0 1026 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
    >
      <path d="M60.44416 384h908.8L514.84416 992z" fill="#FD6F1A"></path>
      <path
        d="M514.84416 992L22.04416 614.4 2.84416 595.2c-6.4-6.4 0-19.2 0-25.6L60.44416 384 514.84416 992zM329.24416 384H706.84416l-192 608L329.24416 384zM214.04416 12.8L329.24416 384H60.44416L175.64416 19.2c6.4-12.8 12.8-19.2 19.2-19.2s12.8 6.4 19.2 12.8zM1026.84416 569.6v25.6c0 6.4-6.4 12.8-12.8 19.2L514.84416 992 969.24416 384l57.6 185.6zM969.24416 384H706.84416L822.04416 19.2c0-12.8 6.4-19.2 12.8-19.2s12.8 6.4 19.2 12.8L969.24416 384z"
        fill="#E34524"
      ></path>
      <path
        d="M514.84416 992L22.04416 614.4 2.84416 595.2c-6.4-6.4 0-19.2 0-25.6L60.44416 384 514.84416 992zM329.24416 384H706.84416l-192 608L329.24416 384z m0 0H60.44416h268.8zM1026.84416 569.6v25.6c0 6.4-6.4 12.8-12.8 19.2L514.84416 992 969.24416 384l57.6 185.6zM969.24416 384H706.84416h262.4z"
        fill="#FDA50F"
      ></path>
      <path d="M329.24416 384h371.2L514.84416 992z" fill="#E34524"></path>
    </svg>
  ),
  gitee: (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
    >
      <path
        d="M510.354286 0c281.856 0 510.317714 228.498286 510.317714 510.354286s-228.461714 510.317714-510.317714 510.317714C228.498286 1020.708571 0 792.210286 0 510.354286 0 228.498286 228.498286 0 510.354286 0z"
        fill="#C71D23"
      ></path>
      <path
        d="M768.694857 226.816H415.817143a189.001143 189.001143 0 0 0-189.001143 189.037714v352.804572c0 13.897143 11.300571 25.234286 25.234286 25.234285h371.712a170.093714 170.093714 0 0 0 170.093714-170.130285v-144.896a25.234286 25.234286 0 0 0-25.197714-25.234286h-289.828572a25.234286 25.234286 0 0 0-25.197714 25.234286v62.976c0 13.092571 9.984 23.881143 22.747429 25.088l2.413714 0.146285h176.457143a25.234286 25.234286 0 0 1 25.088 22.747429l0.109714 2.413714v12.617143a75.593143 75.593143 0 0 1-75.593143 75.593143H365.421714a25.234286 25.234286 0 0 1-25.234285-25.197714v-239.396572c0-40.484571 31.817143-73.545143 71.862857-75.52l3.766857-0.109714h352.768a25.234286 25.234286 0 0 0 25.234286-25.197714V252.050286c0.073143-13.897143-11.227429-25.234286-25.124572-25.234286z"
        fill="#FFFFFF"
      ></path>
    </svg>
  ),
}

export const SourceCodeLink = ({ url, name, desc }) => {
  let svg = icons.github
  if (/coding\.net\//.test(url)) svg = icons.coding
  if (/gitee\.com\//.test(url)) svg = icons.gitee
  if (/gitlab\.com\//.test(url)) svg = icons.gitlab

  return (
    <div>
      <strong> 源码： </strong>
      <a
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        css={css`
          display: inline-flex;
          align-items: center;
          border-radius: 5px;
          background: #8e8e8e29 !important;
          border-bottom: none !important;
          box-shadow: none !important;
          margin-bottom: 1.2rem;
          max-width: 420px;
          :hover {
            background: #c6bcff47 !important;
          }
        `}
      >
        <div
          css={css`
            padding: 10px;
            svg path{
              fill: var(--text-color-body);
            }
          `}
        >
          {svg}
        </div>
        <div
          css={css`
            flex: 1;
            padding: 5px 10px 5px 0;
            h5 {
              font-size: 20px;
              margin: 5px 0 5px 0;
            }
            p {
              margin: 0 0 5px 0;
              font-size: 13px;
            }
          `}
        >
          <h5>{name}</h5>
          <p>{desc}</p>
        </div>
        <div
          css={css`
            padding: 10px;
            height: 100%;
            background: rgba(198, 188, 255, 0.25);
            border-radius: 50%;
            margin-right: 10px;
          `}
        >
          <ChevronRightIcon />
        </div>
      </a>
    </div>
  )
}
