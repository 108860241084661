import React from 'react'
import Alert from '@material-ui/lab/Alert'

export const NoShare = () => {
  return (
    <Alert severity="warning">
      本文特别提示：当前内容发表在个人博客，禁止任何形式的转载、被收录、二次演绎或大范围传播，本段声明覆盖文末内容分享协议!
      如果造成恶意传播、隐私泄露、名誉侵害、权利侵害等，作者将使用法律手段维护权益。
    </Alert>
  )
}
