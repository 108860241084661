import React from 'react'
import styled from '@emotion/styled'
import { rhythm } from '~lib/typography'
import { css } from '@emotion/core'

import Paragraph from './paragraph'
import List from './list'
import Li from './li'
import Code from './code'

import { CodeWithPreview } from './CodeWithPreview'

const H1Title = styled.h1`
  color: var(--text-color-head);
  line-height: 1.35;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
`
const H2Title = styled.h2`
  color: var(--text-color-head);
  text-align: left;
  margin-top: ${rhythm(2)};
  margin-bottom: ${rhythm(1)};
  font-weight: 900;
  line-height: 1.1;
`
const H3Title = styled.h3`
  color: var(--text-color-head);
  text-align: left;
  margin-top: ${rhythm(1.8)};
  margin-bottom: ${rhythm(1)};
  font-size: 1.4rem;
  line-height: 1.5;
`
const H4Title = styled.h4`
  color: var(--text-color-head);
  text-align: left;
  margin-top: ${rhythm(1.6)};
  margin-bottom: 17px;
  font-size: 19px;
  line-height: 1.5;
`
const H5Title = styled.h5`
  color: var(--text-color-head);
  text-align: left;
  margin-top: ${rhythm(1.4)};
  margin-bottom: 17px;
  font-size: 16px;
  line-height: 1.5;
`
const H6Title = styled.h6`
  color: var(--text-color-head);
  text-align: left;
  margin-top: ${rhythm(2)};
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 1.5;
`

const Title = ({ com, ...props }) => {
  const Com = com
  return (
    <Com id={encodeURIComponent(props.children)} {...props}>
      <div
        css={css`
          a {
            display: none;
            width: 20px;
            margin: 0;
          }

          :hover a {
            display: block;
            float: left;
            padding-right: 4px;
            margin-left: -20px;
            line-height: 1.2;
          }
        `}
      >
        <a href={`#${encodeURIComponent(props.children)}`}>
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width=".8em" height=".8em"><path fill="currentColor" d="M361.376 617.376a32 32 0 0 0 45.248 45.248l-45.248-45.248zm301.248-210.752a32 32 0 0 0-45.248-45.248l45.248 45.248zM622.72 147.552l-22.624-22.624 22.624 22.624zm248.896 248.896l-22.624-22.624 22.624 22.624zm-402.24-140.8a32 32 0 1 0 45.216 45.28l-45.248-45.248zm-72.928 616l-22.624-22.656 22.624 22.624zm-248.896 0l22.624-22.656-22.624 22.624zm0-248.928l22.624 22.624-22.624-22.624zM550.144 763.2a32 32 0 0 0-45.248-45.28l45.248 45.248zm197.024-287.552a32 32 0 1 0 45.28 45.248l-45.28-45.248zm-452.544 45.248a32 32 0 1 0-45.248-45.248l45.248 45.248zm112 141.76l256-256-45.248-45.28-256 256 45.248 45.248zm238.72-492.48a144 144 0 0 1 203.648 0l45.248-45.248a208 208 0 0 0-294.144 0l45.248 45.248zm203.648 0a144 144 0 0 1 0 203.648l45.248 45.248a208 208 0 0 0 0-294.144l-45.248 45.248zm-248.896-45.248L469.344 255.68l45.248 45.248 130.752-130.752-45.248-45.248zM373.824 848.96a144 144 0 0 1-203.648 0l-45.248 45.28a208 208 0 0 0 294.144 0l-45.248-45.28zm-203.648 0a144 144 0 0 1 0-203.648l-45.248-45.248a208 208 0 0 0 0 294.144l45.248-45.248zm248.896 45.28l131.072-131.104-45.248-45.248L373.824 848.96l45.248 45.28zm429.92-520.448L747.168 475.616l45.28 45.248L894.24 419.04l-45.248-45.248zM170.176 645.344l124.48-124.448-45.28-45.248-124.48 124.48 45.28 45.216z" /></svg>
        </a>{' '}
        {props.children}
      </div>
    </Com>
  )
}

export default {
  wrapper: ({ children }) => <div className="mdx-wrapper">{children}</div>,
  CodeWithPreview: props => <CodeWithPreview {...props} />,
  h1: props => <Title {...props} com={H1Title} />,
  h2: props => <Title {...props} com={H2Title} />,
  h3: props => <Title {...props} com={H3Title} />,
  h4: props => <Title {...props} com={H4Title} />,
  h5: props => <Title {...props} com={H5Title} />,
  h6: props => <Title {...props} com={H6Title} />,
  p: props => <Paragraph {...props} />,
  ul: props => <List {...props} />,
  // li: props => <Li {...props} />,
  pre: preProps => {
    const props = preToCodeBlock(preProps)
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />
    }
  },
}

// lifted this from mdx-utils
// it doesn't compile it's code and this busted IE, so I'm just vendoring it.
function preToCodeBlock(preProps) {
  if (
    // children is code element
    preProps.children &&
    // code props
    preProps.children.props &&
    // if children is actually a <code>
    preProps.children.props.mdxType === 'code'
  ) {
    // we have a <pre><code> situation
    const { children: codeString, className = '', ...props } = preProps.children.props

    const matches = className.match(/language-(?<lang>.*)/)

    return {
      codeString: codeString.trim(),
      className,
      language: matches && matches.groups && matches.groups.lang ? matches.groups.lang : '',
      ...props,
    }
  }
}

/* eslint react/display-name:0, consistent-return:0 */
