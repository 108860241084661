import React from 'react'
import { graphql } from 'gatsby'
import ExtendedMdxRender from '../components/extendedMdxRender'
import SEO from '../components/seo'
import Container from '../components/container'
import Layout from '../components/layout'
import Hero from '../components/big-hero'
import theme from '../../config/theme'
import { css } from '@emotion/core'
import get from 'lodash/get'

export default function Studio({ data: { site, allMdx } }) {
  const mdx = allMdx.edges[0].node
  return (
    <Layout
      hero={
        <Hero
          title="💬 闲着说"
          text="没那么多精力天天写文章，需要有一个地方想啥说啥，像是自己的微博~"
          bigEmoji="💬"
          noImage={true}
        />
      }
      headerColor={theme.colors.white}
      site={site}
      frontmatter={mdx.fields}
      headerLink="/"
      noFooter={mdx.fields.noFooter}
    >
      <SEO
        frontmatter={mdx.fields}
        metaImage={get(mdx, 'fields.banner.childImageSharp.fluid.src')}
      />
      <article
        css={css`
          width: 100%;
          display: flex;
          twitter-widget {
            margin-left: auto;
            margin-right: auto;
          }
        `}
      >
        <Container
          css={css`
            padding-top: 0;
          `}
        >
          <ExtendedMdxRender>{mdx.body}</ExtendedMdxRender>
        </Container>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        keywords
      }
    }
    allMdx(
      filter: {
        frontmatter: {published: {ne: false}}
        fileAbsolutePath: {regex: "//content/talks//"}
      }
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          fields {
            title
            noFooter
            description
            date
            author
            banner {
              ...bannerImage2000
            }
            bannerCredit
            slug
            description
            keywords
          }
          body
        }
      }
    }
  }
`
