import React, {useEffect, useState} from 'react'
import {css} from '@emotion/core'
import CircularProgress from '@material-ui/core/CircularProgress'

function SlideEmbed({slug}) {
  const [scale, setScale] = useState(0.5)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const wrapperEle = document.querySelector('.mdx-wrapper')
    if (wrapperEle) {
      const rectWidth = wrapperEle.getBoundingClientRect().width
      if (rectWidth > 0) setScale(rectWidth / window.innerWidth)
    }
  }, [])

  const url = `${location.origin}/${slug.replace(/^\//, '')}`

  return (
    <div>
      <div>
        <a target="_blank" href={url}>
          在新页面打开演示页面
        </a>
      </div>
      <div
        css={css`
          width: 100%;
          padding-bottom: 56.25%;
          position: relative;
        `}
      >
        <div
          css={css`
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
          style={{
            display: loaded ? 'none' : 'block',
          }}
        >
          <CircularProgress />
        </div>
        <iframe
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            border: 1px solid #e1e1e1;
          `}
          style={{
            width: `${100 / scale}%`,
            height: `${100 / scale}%`,
            transform: `scale(${scale})`,
            transformOrigin: 'left top',
            display: loaded ? 'block' : 'none',
          }}
          src={url}
          onLoad={() => {
            setLoaded(true)
          }}
        ></iframe>
      </div>
    </div>
  )
}

export default SlideEmbed
