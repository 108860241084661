import React from 'react'
import { css } from '@emotion/core'

// https://github.com/dart-lang/dart-pad/wiki/Embedding-Guide

export class RunFlutter extends React.Component {
  componentDidMount() {
    const loaderScript = window.document.createElement('script')
    loaderScript.type = 'text/javascript'
    loaderScript.src = 'https://dartpad.dev/inject_embed.dart.js'
    window.document.body.appendChild(loaderScript)
  }
  render() {
    const rules = {
      theme: 'dark',
      mode: 'flutter',
      run: 'true',
      split: '60',
      width: '100%',
      height: '800px',
      ...this.props.rules,
    }
    const cn = Object.keys(rules)
      .map(r => `${r}-${rules[r]}`)
      .join(':')
    return (
      <div
        css={css`
          iframe {
            border: 0;
          }
          iframe[src^="https://dartpad.dev"] {
            background: rgba(0, 0, 0, 0.2);
          }
        `}
      >
        <pre>
          <code className={`language-run-dartpad:${cn}`}>{this.props.code}</code>
        </pre>
      </div>
    )
  }
}
