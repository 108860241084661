import React from 'react'

import './device.css'

type base = {
  device: string
  color?: string
  children?: React.ReactChildren
}

type iphone = {
  color: 'silver' | 'gold' | 'spacegray'
  device: 'iphone-8'
  children?: React.ReactChildren
}
type google = {
  color: 'silver' | 'black' | 'blue'
  device: 'google-pixel'
  children?: React.ReactChildren
}
type galaxy = {
  color: 'black' | 'blue'
  device: 'galaxy-s8'
  children?: React.ReactChildren
}
type ipad = {
  color: 'silver' | 'gold' | 'rosegold' | 'spacegray'
  device: 'ipad-pro'
  children?: React.ReactChildren
}
type macbook = {
  color: 'silver' | 'gold' | 'rosegold' | 'spacegray'
  device: 'macbook'
  children?: React.ReactChildren
}
type macbookPro = {
  color: 'silver' | 'spacegray'
  device: 'macbook-pro'
  children?: React.ReactChildren
}

type noColor = {
  device:
    | 'iphone-x'
    | 'google-pixel-2-xl'
    | 'surface-pro'
    | 'surface-book'
    | 'surface-studio'
    | 'imac-pro'
    | 'apple-watch'
  children?: React.ReactChildren
}

function Device(props: iphone): React.ReactElement
function Device(props: google): React.ReactElement
function Device(props: galaxy): React.ReactElement
function Device(props: ipad): React.ReactElement
function Device(props: macbook): React.ReactElement
function Device(props: macbookPro): React.ReactElement
function Device(props: noColor): React.ReactElement
function Device(props: base): React.ReactElement {
  const {device = 'iphone-8', color = 'spacegray', children} = props
  return (
    <div className={`device device-${device} device-${color ? color : ''}`}>
      <div className="device-frame">{children}</div>
      <div className="device-stripe"></div>
      <div className="device-header"></div>
      <div className="device-sensors"></div>
      <div className="device-btns"></div>
      <div className="device-power"></div>
    </div>
  )
}

{
  /* <Device device="iphone-8" color="spacegray"></Device> */
}

export default Device
