/* eslint-disable complexity */
import React from 'react'

const tdStyle = `
  text-align: center;
  vertical-align: top;
  padding: 0px 2px;
  border: none;
`
const trStyle = `
  border: none;
  background: none;
`
const nodeTextStyle = `
  display: inline-block;
  padding: 10px 20px;
  background: #f8f8f836;
  border: 2px solid #000;
`
const tableStyle = `
  width: 100%;
  display: table;
  margin: 0;
`
const lineStyle = `
  width: 1px;
  height: 20px;
`
const topStyle = `
  border-top: 3px solid black;
`
const leftStyle = `
  border-right: 2px solid black;
`
const rightStyle = `
  border-left: 2px solid black;
`

// 组织架构图：
// ```orgchart
// start=>rect: 试一试[http://www.ylsk.com/]
// video=>rect: 点播
// live=>rect: 直播
// interface=>rect: 接口
// chat=>rect: 即时通信
// upload=>rect: 上传
// database=>rect: 数据库
// mysql=>rect: mysql
// mongodb=>rect: mongodb
// start->video+live+interface+chat
// video->upload
// interface->database
// database->mysql+mongodb
// ```
// ->组织架构图<-
// > 组织架构图目前在网上没有找到具体的语法标准，为了实现，我定义了一套规则：
// 1. 每一行为一条语句，语句分为定义和连接，语句前后位置不影响解析；
// 2. 定义语句为： `id=>类型: 文字[标题]`
//     1. 最前面是id，必须
//     2. 紧跟着 `=>` 符号表示定义，必须
//     3. 紧跟着类型 `rect` 目前支持 `rect` `round`两种形状，必须
//     4. 紧跟着为 `:[空格]`，分割定义和文字，必须
//     5. 紧跟着为文字，后面方括号可选标题
// 3. 连接语句：`id->id2[+id3[+id4]]`
//     1. `->` 符号前面是起始节点id，后面跟着下属id
//     2. `->` 后面的id可以单独分开书写，也可以使用`+`号分割多个id，表示`id`下属的多个子节点

function parse(input) {
  let sub

  input = input || ''
  input = input.trim()

  // 基本结构
  const chart = {
    symbols: {},
    start: null,
  }
  // 分割行
  const prelines = input.split('\n')
  const lines = []

  // 筛选符合条件的行
  for (let l = 0, len = prelines.length; l < len; l++) {
    const currentLine = prelines[l]
    if (currentLine.indexOf('->') > 0 || currentLine.indexOf('=>') > 0) {
      lines.push(currentLine)
    }
  }

  // 处理每一行，节点定义和节点走向
  while (lines.length > 0) {
    const line = lines.splice(0, 1)[0]
    if (line.indexOf('=>') >= 0) {
      // definition
      const parts = line.split('=>')
      const symbol = {
        key: parts[0],
        symbolType: parts[1],
        text: null,
        title: null,
      }

      if (symbol.symbolType.indexOf(': ') >= 0) {
        sub = symbol.symbolType.split(': ')
        symbol.symbolType = sub[0]
        symbol.text = sub[1]
      }

      const startIndex = symbol.text.indexOf('[') + 1
      const endIndex = symbol.text.indexOf(']')
      if (startIndex >= 0 && endIndex >= 0) {
        symbol.title = symbol.text.substring(startIndex, endIndex)
        symbol.text = symbol.text.substring(0, startIndex - 1)
      }

      chart.symbols[symbol.key] = symbol
    } else if (line.indexOf('->') >= 0) {
      const orgSymbols = line.split('->')
      const thisSymb = chart.symbols[orgSymbols[0]]

      if (!thisSymb) return
      if (orgSymbols[1] == '') return

      if (!chart.start) {
        chart.start = thisSymb
      }
      const nextSymbols = orgSymbols[1].split('+')
      for (let i = 0, lenS = nextSymbols.length; i < lenS; i++) {
        const nextSymbolId = nextSymbols[i]
        if (nextSymbolId == '') return
        const nextSymbol = chart.symbols[nextSymbolId]
        if (!nextSymbol) return
        if (!thisSymb.children) thisSymb.children = []
        thisSymb.children.push(nextSymbol)
      }
    }
  }
  return chart
}

function draw(tree, level, uid) {
  const childNodes = tree.children
  const childLength = (childNodes && childNodes.length) || 0

  let outStr = `<table style="${tableStyle}" cellpadding="0" cellspacing="0" border="0"><tbody>`

  // 渲染当前节点
  const nodeCell_colspan = childLength * 2 || 2
  const nodeCell = `<td style="${tdStyle}" colspan="${nodeCell_colspan}"><div style="${nodeTextStyle}" title="${tree.title
    }">${tree.text}</div></td>`
  outStr += `<tr styles="${trStyle}">${nodeCell}</tr>`

  if (childLength > 0) {
    //竖线
    const downLineCell_colspan = childLength * 2
    const downLineCell =
      `<td style="${tdStyle}" colspan="${downLineCell_colspan}">` +
      `<table style="${tableStyle}" cellpadding="0" cellspacing="0" border="0"><tbody>` +
      `<tr styles="${trStyle}"><td style="${tdStyle}${leftStyle}${lineStyle}"></td><td style="${tdStyle}${rightStyle}${lineStyle}"></td></tr>` +
      `</tbody></table>` +
      `</td>`
    outStr += `<tr styles="${trStyle}">${downLineCell}</tr>`

    //横线
    let vlines = ''
    for (let i = 0; i < childLength; i++) {
      const isLeftSide = i == 0 ? '' : topStyle
      const isRightSide = i == childLength - 1 ? '' : topStyle
      vlines += `<td style="${tdStyle}${leftStyle}${lineStyle}${isLeftSide}"></td><td style="${tdStyle}${rightStyle}${lineStyle}${isRightSide}"></td>`
    }
    outStr += `<tr styles="${trStyle}">${vlines}</tr>`

    // 递归调用产生数据
    let childStrs = ''
    for (let i = 0; i < childLength; i++) {
      childStrs += `<td style="${tdStyle}" colspan="2">${draw(childNodes[i], level + 1, uid)}</td>`
    }
    outStr += `<tr styles="${trStyle}">${childStrs}</tr>`
  }

  outStr += '</tbody></table>'

  return outStr
}

const render__ = (code, options) => {
  const chart = parse(code)
  return `<div class="orgChart">${draw(chart.start, 1, (options && options.uid) || 'uid')}</div>`
}

export class OrgChart extends React.Component {
  runner = () => { }
  resultContainerRef
  editorRef
  componentDidMount() {
    this.resultContainerRef.innerHTML = render__(this.props.codeStr)
  }
  runCases = () => {
    this.runner()
  }
  render() {
    return (
      <div
        ref={ref => {
          this.resultContainerRef = ref
        }}
        style={{ backgroundColor: 'transparent', marginBottom: '1.2rem' }}
      />
    )
  }
}
