import React from 'react'

// 如果里面包含gif 图片，解析会出现错误 <img src={gif} />
export const WithFigcaption = ({children, title}) => {
  return (
    <figure className="gatsby-resp-image-figure">
      <span
        className="gatsby-resp-image-wrapper"
        style={{
          position: 'relative',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '743px',
        }}
      >
        {children}
      </span>
      <figcaption className="gatsby-resp-image-figcaption">{title}</figcaption>
    </figure>
  )
}
